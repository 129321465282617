<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-user-check"></i> สถานะเครดิตลูกค้า </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <page-customer-credit/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pageCustomerCredit from './view/page.customer_credit';
export default {
  name: 'customer_credit-view-index',
  data () {
    return {}
  },
  methods: {},
  components: {
    pageCustomerCredit
  },
  created () {}
}
</script>
