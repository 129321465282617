<template>
  <b-modal id="dialog_customer_credit_edit" size="lg">
    <template #modal-title>
      <h3><i class="fa fa-pen"></i> แก้ไขข้อมูลสถานะเครดิตลูกค้า</h3>
    </template>
    <div>
      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <b-form @submit.prevent="handleSubmit(editHandler)">
          <div class="mb-2 row">
            <label class="col-sm-5 col-form-label label_algin_right">ชื่อบริษัท</label>
            <div class="col-sm-7">
              <input  type="text"
                      class="form-control"
                      v-model="customer.name"
                      disabled />
            </div>
          </div>
          <div class="mb-2 row">
            <label class="col-sm-5 col-form-label label_algin_right">สถานะเครดิต</label>
            <div class="col-sm-7">
              <Select2 v-model="customer.credit_status" :options="customerCreditTypes" :settings="{allowClear: true}" placeholder="สถานะเครดิต"/>
              <base-input name="credit_status" :rules="{required: true}" v-model="customer.credit_status" type="hidden"></base-input>
            </div>
          </div>
          <div class="mb-2 row">
            <label class="col-sm-5 col-form-label label_algin_right">จำนวนเครดิตที่อนุญาต</label>
            <div class="col-sm-7">
              <base-input name="credit_line" v-decimal-format
                          :rules="{required: true}"
                          placeholder="จำนวนเครดิตที่อนุญาต"
                          v-model="customer.credit_line">
              </base-input>
            </div>
          </div>
          <button ref="editCustomerCreditForm" style="display:none;">Submit</button>
        </b-form>
      </validation-observer>
    </div>
    <template #modal-footer>
      <b-button variant="primary" @click="onOutsideSubmit()" class="style_btn"><i class="fa fa-check"></i> บันทึก</b-button>
      <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
    </template>
  </b-modal>
</template>
<script>
import Constants from '../../../util/constants';
export default {
  name: 'customer_credit-view-dialog-customer_credit',
  data(){
    return {
      customerCreditTypes: []
    };
  },
  props: [
    'customer'
  ],
  methods: {
      onOutsideSubmit(){
        this.$refs.editCustomerCreditForm.click();
      },
      async editHandler () {
        const result = await this.HttpServices.putData(`/ab_customer/credit/${this.customer.id}`, this.customer);
        if(result&&result.status.code=="200"){
          this.AlertUtils.alert('success', `บันทึกสำเร็จ`);
          this.$bvModal.hide('dialog_customer_credit_edit');
          this.$emit("successFuction", "");
        }else{
          this.AlertUtils.alert('warning', result.status.message);
        }
      },
    close () {
      this.$bvModal.hide('dialog_customer_credit_edit');
    }
  },
  mounted() {
    this.customerCreditTypes = Constants.customer_credit_types;
  },
}
</script>
